import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import ItemsList from '../components/ItemsList';
import { HTMLContent } from '../components/Content';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const EventsPage = ({
  data: {
    markdownRemark,
    upcoming: { edges: upcoming },
    past: { edges: past },
    site: {
      siteMetadata: { title },
    },
  },
  pageContext: { nextPagePath },
}) => {
  const location = `${process.env.PAGE_DOMAIN}/events/`;

  return (
    <Layout pageType="PageWithLongIntro">
      <div className="main events-page">
        <section className="hero wrapper">
          <div className="hero-text">
            <h1 className="page-title-with-description">
              {markdownRemark.frontmatter.title}
            </h1>
          </div>
        </section>
        <section>
          <Helmet title={`Events | ${title}`} />
          <GatsbySeo
            title={markdownRemark.frontmatter.title}
            titleTemplate={`%s | ${title}`}
            description={markdownRemark.frontmatter.teaser}
            canonical={markdownRemark.frontmatter.canonical || location.href}
            openGraph={{
              title: `${markdownRemark.frontmatter.title}`,
              description: `${markdownRemark.frontmatter.teaser}`,
              url: `${process.env.PAGE_DOMAIN}${markdownRemark.fields.slug}`,
            }}
          />
          <div className="container">
            <HTMLContent className="content paragraph-white" content={markdownRemark.html} />
          </div>
        </section>
        {upcoming && upcoming.length > 0 && (
          <section>
            <div className={`grid-container flex-start ${past && past.length > 0 ? 'upcoming-events-section' : ''}`}>
              <h2 className="section-title off-sm-1 col-sm-10 title-white">Upcoming events</h2>
              <div className="content off-sm-1 col-sm-10">
                <ItemsList initialItems={upcoming} />
              </div>
            </div>
          </section>
        )}
        {past && past.length > 0 && (
          <section>
            <div className="grid-container flex-start">
              <h2 className={`section-title off-sm-1 col-sm-10 ${upcoming && upcoming.length > 0 ? "title-white-desktop" : "title-white"}`}>
                Past events
              </h2>
              <div className="content off-sm-1 col-sm-10">
                <ItemsList initialItems={past} secondPage={nextPagePath} />
              </div>
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
};

export default EventsPage;

export const eventsQuery = graphql`
  query EventsQuery($id: String!, $date: Date!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        teaser
      }
      fields {
        slug
      }
    }
    upcoming: allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, date: { gte: $date } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
    past: allMarkdownRemark(
      limit: $limit
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, date: { lt: $date } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
  }
`;
